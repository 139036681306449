import React from 'react';
import HeroSection from '../components/HeroSection';
import ScrollContainer from '../components/ScrollContainer';
import ConnectWallet from '../components/ConnectWallet';
import CardSection from '../components/CardSection';

export default function Home() {
  return (
    <>
    <HeroSection />
    <ScrollContainer />
    <ConnectWallet />
    <hr className='-mx-5' />
    <CardSection />
    </>

    // <div className="bg-gray-100 dark:bg-gray-900 py-8 px-8">
    //   <h2 className="text-2xl font-bold text-gray-800 dark:text-white">Home</h2>
    //   <p className="text-gray-700 dark:text-gray-300 mt-4">Welcome to my website!</p>
    // </div>
  );
}
