import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function Theme({ children }) {
    const theme = useSelector((state) => state.theme ? state.theme.value : 'light');


  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove('light', 'dark');
    root.classList.add(theme);
  }, [theme]);
  return children;
}
