import React from 'react';
import basePrint1 from '../assets/images/basePrint1.webp';
import framePrint1_light from '../assets/images/framePrint1.webp';
import framePrint1_dark from '../assets/images/framePrint1_dark.webp';
import { Colors } from '../utils/Colors';
import { ArrowRightIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';

export default function Step3() {
  const theme = useSelector((state) => state.theme.value);
  return (
    <div className="primary_bg3 border border-gray-200 rounded-lg shadow p-4 mb-10">
      <div>
        <h3 className="text-center text-2xl xl:text-4xl mb-5 dark:text-white ">
          Step 3: Putting it all together
        </h3>
        <hr className="border-[#c5c5c5] " />
      </div>
      <section className="flex flex-col xl:flex-row my-5 xl:p-6">
        <section className="xl:w-2/3 text-center ">
          <p className="dark:text-white">We have a base press.</p>
          <br />
          <div className="flex justify-center">
            <div className="white_con rounded-lg border bg-white dark:bg-transparent border-[#E5E7EB] shadow-md p-3">
              <img
                src={basePrint1}
                loading="lazy"
                width={70}
                height={70}
                className="rounded-full"
              />
            </div>
          </div>
          <br />
          <p className="dark:text-white">We have 20 colours. </p>
          <br />
          <div className="flex justify-center items-center ">
            <div className="flex flex-wrap justify-center white_con rounded-lg border bg-white dark:bg-transparent border-[#E5E7EB] shadow-md  p-3">
              {Colors.map((Color) => (
                <div className={` m-1 h-3 w-3 ${Color.Code}   `} key={Color.id}></div>
              ))}
            </div>
          </div>
          <div className="flex flex-wrap"></div>
          <br />
          <p className="dark:text-white truncate">Now we just need to combine them.</p>
        </section>
        <section className="flex items-center justify-center xl:p-6">
          <div className="xl:block hidden">
            <ArrowRightIcon className="w-11 h-12 text-black dark:text-white " />
          </div>
          <div className="flex justify-center xl:hidden my-3">
            <ArrowDownIcon className="w-11 h-12 text-black dark:text-white " />
          </div>
        </section>
        <section className="xl:w-2/3 flex flex-col  items-center justify-center dark:text-white">
          <p className="dark:text-white"> We perform some background magic and viola!</p>
          <br />
          <div className=" xl:w-8/12 flex   justify-evenly white_con rounded-lg border bg-white dark:bg-transparent border-[#E5E7EB] shadow-md py-2">
            {theme === 'light' ? (
              <img
                src={framePrint1_light}
                loading="lazy"
                width={170}
                height={170}
                className="rounded-full"
              />
            ) : (
              <img
                src={framePrint1_dark}
                loading="lazy"
                width={170}
                height={170}
                className="rounded-full"
              />
            )}
          </div>
          <br />A new press is created.
        </section>
      </section>
    </div>
  );
}
