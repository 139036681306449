export const Colors = [
    {
      id: 1,
      Number: 68,
      Code: 'bg-[#ff6363]',
    },
    {
      id: 2,
      Number: 22,
      Code: 'bg-[#a0bcc2]',
    },
    {
      id: 3,
      Number: '3e',
      Code: 'bg-[#f8b400]',
    },
    {
      id: 4,
      Number: '14',
      Code: 'bg-[#0e7185]',
    },
    {
      id: 5,
      Number: 'c4',
      Code: 'bg-[#c88ea7]',
    },
    {
      id: 6,
      Number: 'f0',
      Code: 'bg-[#47a992]',
    },
    {
      id: 7,
      Number: '88',
      Code: 'bg-[#fbc5c5]',
    },
    {
      id: 8,
      Number: '53',
      Code: 'bg-[#7c6b65]',
    },
    {
      id: 9,
      Number: '93',
      Code: 'bg-[#dd4a48]',
    },
    {
      id: 10,
      Number: '9e',
      Code: 'bg-[#ffc26f]',
    },
    {
      id: 11,
      Number: 'E0',
      Code: 'bg-[#a04671]',
    },
    {
      id: 12,
      Number: 'E7',
      Code: 'bg-[#df7857]',
    },
    {
      id: 13,
      Number: '64',
      Code: 'bg-[#698269]',
    },
    {
      id: 14,
      Number: '02',
      Code: 'bg-[#ffdf91]',
    },
    {
      id: 15,
      Number: '48',
      Code: 'bg-[#b5d5c5]',
    },
    {
      id: 16,
      Number: 'CE',
      Code: 'bg-[#f48484]',
    },
    {
      id: 17,
      Number: 'b7',
      Code: 'bg-[#ccc2bb]',
    },
    {
      id: 18,
      Number: '2F',
      Code: 'bg-[#7a9ac2]',
    },
    {
      id: 19,
      Number: 'DF',
      Code: 'bg-[#66cbdf]',
    },
    {
      id: 20,
      Number: '62',
      Code: 'bg-[#9a86a4]',
    },
  ];