import React from 'react';
import Step1 from '../components/Step1';
import Step2 from '../components/Step2';
import Step3 from '../components/Step3';

export default function HowItsMade() {
  return (
    <div className='p-3'>
      <div className="font-Text_Me_One text-4xl xl:text-7xl text-center my-8 dark:text-white">How is a Press made?</div>
      <Step1 />
      <Step2 />
      <Step3 />
    </div>
  );
}
