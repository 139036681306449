import React from 'react';
// Light
import Frame1 from '../assets/images/framePrint1.png';
import Frame2 from '../assets/images/framePrint2.png';
import Frame3 from '../assets/images/framePrint3.png';
import Frame4 from '../assets/images/framePrint4.png';
// Dark
import DarkFrame1 from '../assets/images/specialPrint1.png';
import DarkFrame2 from '../assets/images/specialPrint2.png';
import DarkFrame3 from '../assets/images/specialPrint3.png';
import DarkFrame4 from '../assets/images/specialPrint4.png';
import { useSelector } from 'react-redux';

const ScrollContainer = () => {
  const theme = useSelector((state) => state.theme.value);
  return (
    <>
      <div className="flex justify-center">
        <hr className=" h-1   border-0 rounded my-8 md:my-8 bg-gray-700 w-11/12 md:w-10/12 text-center text-4xl" />
      </div>

      <div className="overflow-auto no-scrollbar ">
        <section className=" flex no-scrollbar justify-center scrollContent max-xl:px-8  gap-1 xl:grid xl:grid-cols-4">
          <div className=" w-[350px] p-4 snap-always snap-center">
            <div className=" flex flex-col justify-center align-middle w-80">
              <div className="frame">
                <div className="mat">
                  <div className="h-full w-full flex justify-center align-middle">
                    {theme === 'light' ? (
                      <img
                        alt="logo"
                        loading="lazy"
                        width={200}
                        height={100}
                        decoding="async"
                        data-nimg={1}
                        className="m-5 rounded-md text-transparent"
                        src={Frame1}
                      />
                    ) : (
                      <img
                        alt="logo"
                        loading="lazy"
                        width={200}
                        height={100}
                        decoding="async"
                        data-nimg={1}
                        className="m-5 rounded-md text-transparent"
                        src={DarkFrame1}
                      />
                    )}
                  </div>
                </div>
              </div>
              <h3 className={`${theme === 'light' ? '' : 'text-white'} text-center`}>Index</h3>
            </div>
          </div>
          <div className=" w-[350px] p-4 snap-always snap-center">
            <div className=" flex flex-col justify-center align-middle w-80">
              <div className="frame">
                <div className="mat">
                  <div className="h-full w-full flex justify-center align-middle">
                  {theme === 'light' ? (
                      <img
                        alt="logo"
                        loading="lazy"
                        width={200}
                        height={100}
                        decoding="async"
                        data-nimg={1}
                        className="m-5 rounded-md text-transparent"
                        src={Frame2}
                      />
                    ) : (
                      <img
                        alt="logo"
                        loading="lazy"
                        width={200}
                        height={100}
                        decoding="async"
                        data-nimg={1}
                        className="m-5 rounded-md text-transparent"
                        src={DarkFrame2}
                      />
                    )}
                  </div>
                </div>
              </div>
              <h3 className={`${theme === 'light' ? '' : 'text-white'} text-center`}>Middle</h3>
            </div>
          </div>
          <div className=" w-[350px] p-4 snap-always snap-center">
            <div className=" flex flex-col justify-center align-middle w-80">
              <div className="frame">
                <div className="mat">
                  <div className="h-full w-full flex justify-center align-middle">
                  {theme === 'light' ? (
                      <img
                        alt="logo"
                        loading="lazy"
                        width={200}
                        height={100}
                        decoding="async"
                        data-nimg={1}
                        className="m-5 rounded-md text-transparent"
                        src={Frame3}
                      />
                    ) : (
                      <img
                        alt="logo"
                        loading="lazy"
                        width={200}
                        height={100}
                        decoding="async"
                        data-nimg={1}
                        className="m-5 rounded-md text-transparent"
                        src={DarkFrame3}
                      />
                    )}
                  </div>
                </div>
              </div>
              <h3 className={`${theme === 'light' ? '' : 'text-white'} text-center`}>Ring</h3>
            </div>
          </div>
          <div className=" w-[350px] p-4 snap-always snap-center">
            <div className=" flex flex-col justify-center align-middle w-80">
              <div className="frame">
                <div className="mat">
                  <div className="h-full w-full flex justify-center align-middle">
                  {theme === 'light' ? (
                      <img
                        alt="logo"
                        loading="lazy"
                        width={200}
                        height={100}
                        decoding="async"
                        data-nimg={1}
                        className="m-5 rounded-md text-transparent"
                        src={Frame4}
                      />
                    ) : (
                      <img
                        alt="logo"
                        loading="lazy"
                        width={200}
                        height={100}
                        decoding="async"
                        data-nimg={1}
                        className="m-5 rounded-md text-transparent"
                        src={DarkFrame4}
                      />
                    )}
                  </div>
                </div>
              </div>
              <h3 className={`${theme === 'light' ? '' : 'text-white'} text-center`}>Little</h3>
            </div>
          </div>
        </section>
      </div>

      <div className="flex justify-center">
        <hr className=" h-1   border-0 rounded my-8 md:my-8 bg-gray-700 w-11/12 md:w-10/12 text-center text-4xl" />
      </div>
    </>
  );
};

export default ScrollContainer;
