import React from 'react';

export default function HelloWorld() {
  return (
    <div className="primary_bg1 border border-gray-200 rounded-lg shadow p-4 mb-10 text-center xl:w-[50vw] mx-auto">
      <div className="text-center text-2xl xl:text-4xl mb-5 ">
        <h3 className="text-center text-2xl xl:text-4xl mb-5 ">Hello World</h3>
        <hr className="border-[#c5c5c5] " />
      </div>
      <p className="mt-5 mb-3">Hello. My name is pressnft.eth and We are the creator of Press.</p>
      <p className="mb-3">
        Throughout its creation, We dedicated over 200 hours, tackled new technology, cried at how
        hard making art is and to be honest had an amazing time doing it all. We must say that We are
        immensely proud of the outcome. It stands as one of my most notable achievements. <br />{' '}
      </p>
      <p className="mb-3">
        We sincerely hope that you enjoy the project and it brings you as much joy to experience as
        it did for me to make. If you ever require assistance, have any inquiries or just fancy a
        chat please don't hesitate to reach out.
      </p>
      <p>Happy Pressing!</p>
    </div>
  );
}
