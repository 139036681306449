import './App.css';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import './assets/style/style.scss';

import Theme from './components/Theme';
import HowItsMade from './pages/HowItsMade';
import About from './pages/About';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Theme>
        <div className={'dark:bg-black animation'}>
          <div className=" mx-auto ">
            <div className="min-h-full">
              <Header />
              <div>
                <div className="mx-auto container dark:text-white  overflow-hidden">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/how_its_made" element={<HowItsMade />} />
                    <Route path="/about" element={<About />} />
                  </Routes>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </Theme>
    </>
  );
}

export default App;
