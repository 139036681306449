import React from 'react';
import { ArrowRightIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { Colors } from '../utils/Colors';



export default function Step2() {
  return (
    <div className="primary_bg2 border border-gray-200 rounded-lg shadow p-4 mb-10">
      <div>
        <h3 className="text-center text-2xl xl:text-4xl mb-5 dark:text-white ">
          Step 2: Getting Colours
        </h3>
        <hr className="border-[#c5c5c5] " />
      </div>
      <section className="flex flex-col xl:flex-row my-5 xl:p-6">
        <section className="xl:w-2/3 text-center ">
          <p className="dark:text-white">Now we have the press we need to get the colours.</p>
          <br />
          <p className="dark:text-white">
            To do this we take your wallet address, remove the 0x and cut it up into sections.
          </p>
          <br />
          <div>
            <div className=" white_con rounded-lg border bg-white dark:bg-transparent border-[#E5E7EB] shadow-md p-2 truncate ">
              <p className="dark:text-white truncate">0x68223e14c4f08853939eE0E7640248Ceb72FdF62</p>
              <div className="py-3">
                <ArrowDownIcon className="w-5 h-5 text-black dark:text-white mx-auto " />
              </div>
              <div className="grid grid-cols-4 gap-1">
                {Colors.map((Color) => (
                  <div
                    className="rounded-md border  bg-white dark:bg-transparent border-[#E5E7EB] shadow-md dark:text-white"
                    key={Color.id}
                  >
                    {Color.Number}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <br />
          <p className="dark:text-white">
            We then take each section an assign it one of our 20 vibrant colours.
          </p>
        </section>
        <section className="flex items-center justify-center xl:p-6">
          <div className="xl:block hidden">
            <ArrowRightIcon className="w-11 h-12 text-black dark:text-white " />
          </div>
          <div className="flex justify-center xl:hidden my-3">
            <ArrowDownIcon className="w-11 h-12 text-black dark:text-white " />
          </div>
        </section>
        <section className="xl:w-2/3 text-center">
          <br />
          <p className="dark:text-white">
            You have a 5% chance of getting each colour each time one is assigned.{' '}
          </p>
          <br />
          <div className="white_con rounded-lg border bg-white dark:bg-transparent border-[#E5E7EB] shadow-md p-2">
            <p className="dark:text-white">So our existing sections become.</p>
            <br />
            <div className="grid grid-cols-4 gap-2">
              {Colors.map((Color) => (
                <div
                  className={`rounded-md border border-[#E5E7EB] shadow-md ${Color.Code}   `}
                  key={Color.id}
                >
                  {Color.Number}
                </div>
              ))}
            </div>
            <div className="text-left">
              <span className="text-xs dark:text-white">
                *These colours are just for this example.
              </span>
            </div>
          </div>
          <br />
          <p className="dark:text-white">Now we can move on to the final step!</p>
        </section>
      </section>
    </div>
  );
}
