import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, LightBulbIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTheme } from '../store/themeSlice';
import logo_light from '../assets/images/logo_light.webp';
import logo_dark from '../assets/images/logo_dark.webp';
import { useAccount } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';

const navigation = [
  { name: 'How Its Made', to: 'how_its_made', current: false },
  { name: 'About', to: 'about', current: false },
];

export default function Header() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.value);

  const { address, isConnecting, isDisconnected } = useAccount();

  console.log('connect', address, isConnecting, isDisconnected);

  return (
    <Disclosure
      as="nav"
      className={` border-gray-200 dark:bg-black sticky top-0 bg-white z-10 dark:text-white `}
    >
      {({ open }) => (
        <>
          <div className="mx-auto container px-4 sm:px-6 lg:px-8 sticky top-0 z-10">
            <div className="flex h-16 justify-between">
              <div className="md:flex hidden">
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/">
                    {theme === 'light' ? (
                      <img
                        className="block h-8 w-auto lg:hidden"
                        src={logo_light}
                        alt="Your Company"
                      />
                    ) : (
                      <img
                        className="block h-8 w-auto lg:hidden"
                        src={logo_dark}
                        alt="Your Company"
                      />
                    )}
                    {theme === 'light' ? (
                      <img
                        className="hidden h-8 w-auto lg:block"
                        src={logo_light}
                        alt="Your Company"
                      />
                    ) : (
                      <img
                        className="hidden h-8 w-auto lg:block"
                        src={logo_dark}
                        alt="Your Company"
                      />
                    )}
                  </Link>
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8 dark:text-white">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={classNames(
                        item.current
                          ? 'border-indigo-500 text-gray-900'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium dark:text-white'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              
              <div className="hidden md:ml-6 md:flex md:items-center">
              {!isDisconnected && (
                <ConnectButton
                  accountStatus={{
                    smallScreen: 'avatar',
                    largeScreen: 'full',
                  }}
                  showBalance={{
                    smallScreen: false,
                    largeScreen: true,
                  }}
                  label="Connect Wallet"
                />
              )}
                <button
                  type="button"
                  className="rounded-full  p-1 dark:text-white text-black "
                  onClick={() => dispatch(toggleTheme())}
                >
                  <LightBulbIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="-mr-2 flex items-center justify-end w-full md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-gray-500 focus:outline-none bg-transparent">
                  <span className="sr-only">Open main menu</span>
                  {theme === 'light' ? (
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src={logo_light}
                      alt="Your Company"
                    />
                  ) : (
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src={logo_dark}
                      alt="Your Company"
                    />
                  )}
                  {/* {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )} */}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden dropdown bg-slate-100 absolute w-[95%]">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="nav"
                  to={item.to}
                  className={classNames(
                    item.current
                      ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                      : 'border-transparent',
                    'block border-l-4 py-2 pl-3 pr-4 text-base font-medium dark:text-white text-black'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <button
                  type="button"
                  className="rounded-full  p-1 dark:text-white text-black "
                  onClick={() => dispatch(toggleTheme())}
                >
                  <LightBulbIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
