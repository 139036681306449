import React from 'react';

export default function MetaData() {
  return (
    <div className="primary_bg2 border border-gray-200 rounded-lg shadow p-4 mb-10 text-center xl:w-[50vw] mx-auto">
      <div className="text-center text-2xl xl:text-4xl mb-5 ">
        <h3 className="text-center text-2xl xl:text-4xl mb-5 ">Where is my Metadata Stored</h3>
        <hr className="border-[#c5c5c5] " />
      </div>
      <p className="mt-5 mb-3">
        While some may not mind how or where your NFT metadata is stored, We given it
        significant consideration. Ensuring the immutability of your data and ensuring Press are
        "rug-proof" is of utmost importance to me.
      </p>
      <p className="mb-3">
        During the generation process, until the entire collection is fully minted, your Press will
        be stored on a centralized server maintained by me. We implemented robust security
        measures and regularly back up all data to guarantee its safety.
      </p>
      <p className="mb-3">
        Once the collection is completely minted, We will upload all images and metadata to IPFS
        (InterPlanetary File System) to ensure decentralized hosting of the data. This approach,
        although unconventional, allows for minor adjustments such as enhancing image quality and
        including additional information in the metadata. For instance, We possess a comprehensive
        list of all colors used in each press, but We have chosen not to include them as traits in
        the metadata. If the community deems it beneficial, We can still incorporate this information
        during the IPFS upload phase.
      </p>
      <p className="mb-3">
        By taking these steps, We aim to ensure the long-term integrity and security of your Press
        while also remaining open to community input and improvement opportunities.
      </p>
    </div>
  );
}
