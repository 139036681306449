import React from 'react';

export default function SmartContract() {
  return (
    <div className="primary_bg4 border border-gray-200 rounded-lg shadow p-4 mb-10 text-center xl:w-[50vw] mx-auto">
      <div className="text-center text-2xl xl:text-4xl mb-5 ">
        <h3 className="text-center text-2xl xl:text-4xl mb-5 ">The Smart Contract</h3>
        <hr className="border-[#c5c5c5] " />
      </div>
      <p className="mt-5 mb-3">
        We want to be transparent and let you know that this is the first smart contract We created.
        Initially, We felt a bit nervous, but as We delved deeper into the documentation, We realized
        that it's just code, something We are already very familiar with.
      </p>
      <p className="mb-3">
        To ensure that We had a solid foundation, We enrolled in an excellent course taught by the
        amazing{' '}
        <a
          href="hhttps://twitter.com/pressnftxyz"
          target="_blank"
          className="underline text-sky-500"
        >
          Patrick Collins
        </a>
        , an engineer and developer advocate working on Chainlink. Let me tell you, this guy really
        knows his stuff. The course covered everything from the basics of blockchain to deploying
        NFTs, and it helped me gain confidence in creating contracts.
      </p>
      <p className="mb-3">
        As many of you may know, the blockchain industry moves at a rapid pace, and We soon
        discovered that the team at Azuki had developed a new implementation of the ERC-721
        contract, the{' '}
        <a href="https://www.erc721a.org/" target="_blank" class="underline text-sky-500">
          ERC-721A
        </a>
        . To stay up to date with the latest standards, We decided to use their contract.
      </p>
      <p className="mb-3">
        As mentioned before, We have incorporated functionality that allows me to update NFT metadata
        once it has been deployed to IPFS. Additionally, We have included the ability to gift Press
        as a way to express gratitude to those who have helped me create and test Press, as well as
        to conduct giveaways for the community. Apart from these features, the contract adheres to
        industry standards.
      </p>
      <p>
        We welcome any feedback on the website or the contract. We enjoy discussing technology and We
        always eager to improve my understanding and skills. Your input is greatly appreciated.
      </p>
    </div>
  );
}
