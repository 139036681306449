import React from 'react';
import telegram from '../assets/images/telegram.png';
import twitter from '../assets/images/twitter.png';

export default function Footer() {
  return (
    <div className=' shadow-xl'>
      <div className="mx-auto container px-4 sm:px-6 lg:px-8 flex justify-center pt-3 pb-6 gap-4">
        <a href="https://t.me/pressnftxyz" target="_blank">
          <img src={telegram} className="w-8 hover:scale-110 duration-300" />
        </a>
        <a href="https://twitter.com/pressnftxyz" target="_blank">
          <img src={twitter} className="w-8 hover:scale-110 duration-300" />
        </a>
      </div>
    </div>
  );
}
