import React from 'react'
import HelloWorld from '../components/HelloWorld'
import RoadMap from '../components/RoadMap'
import MetaData from '../components/MetaData'
import SmartContract from '../components/SmartContract'
import Mentions from '../components/Mentions'

export default function About() {
  return (
    <div className='p-4'>
        <h1 className='font-Text_Me_One text-4xl xl:text-7xl text-center my-8 dark:text-white '>Get The Low Down</h1>
        <HelloWorld />
        <RoadMap />
        <MetaData />
        <SmartContract />
        {/* <Mentions /> */}
    </div>
  )
}

