import { ConnectButton } from '@rainbow-me/rainbowkit';
import React, { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { useSelector } from 'react-redux';
import logo_light from '../assets/images/logo_light.webp';
import logo_dark from '../assets/images/logo_dark.webp';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const ConnectWallet = () => {
  const { isDisconnected } = useAccount();
  const theme = useSelector((state) => state.theme.value);
  const targetTime = new Date('2024-01-01T00:00:00').getTime();

  const calculateTimeLeft = () => {
    const difference = +new Date(targetTime) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // Update the countdown every second
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the interval on unmount
    return () => clearTimeout(timer);
  });

  return (
    <div className="flex flex-col  justify-center text-center my-6">
      <div className="flex flex-col justify-center items-center">
        <div className="rk-connect_wrapper">
          {/* <button
            className="rk-connect-button"
            type="button"
            data-testid="rk-connect-button"
          >
            Connect Wallet
          </button> */}
          {isDisconnected ? (
            <ConnectButton
              accountStatus={{
                smallScreen: 'avatar',
                largeScreen: 'full',
              }}
              showBalance={{
                smallScreen: false,
                largeScreen: true,
              }}
              label="Connect Wallet"
            />
          ) : (
            <div className="flex flex-col justify-center items-center font-Text_Me_One">
              <div className="flex flex-col">
                <h3 className="text_primary text-xl mb-3">
                  Select your amount and press the fingerprint to mint.
                </h3>
                <button className=" mint_button">
                  {theme === 'light' ? (
                    <img
                      data-tooltip-id="my-tooltip-2"
                      alt="logo"
                      loading="lazy"
                      width="70"
                      height="70"
                      decoding="async"
                      data-nimg="1"
                      class="rounded-full"
                      src={logo_light}
                    />
                  ) : (
                    <img
                      data-tooltip-id="my-tooltip-2"
                      alt="logo"
                      loading="lazy"
                      width="70"
                      height="70"
                      decoding="async"
                      data-nimg="1"
                      class="rounded-full"
                      src={logo_dark}
                    />
                  )}
                </button>
                <ReactTooltip
                  id="my-tooltip-2"
                  place="bottom"
                  variant="info"
                  content={`Mint will be available in ${timeLeft.hours}h : ${timeLeft.minutes}m : ${timeLeft.seconds}s`}
                />
                <div className="flex justify-center">
                  <h5 className="text_primary text-xl mb-4 mt-2 font-Text_Me_One">
                    Mint
                    <select className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2.5 mx-3 text-xl">
                      <option className="p-2" value={1}>
                        1
                      </option>
                      <option value={2}>2</option>
                    </select>
                    Press
                  </h5>
                </div>
                <h5 className="text_primary text-sky-500 underline cursor-pointer text-center">
                  {/* Want to mint to an address? */}
                  {timeLeft.hours}h : {timeLeft.minutes}m : {timeLeft.seconds}s
                </h5>
                <h3 className="text_primary text-md mt-3 ">
                  *Want A dark Mode version? Simply Mint 2 Press for the same address.
                </h3>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
