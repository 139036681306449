import React from 'react';
import { useSelector } from 'react-redux';
import press_video from '../assets/images/press_video.mp4';

const HeroSection = () => {
  const theme = useSelector((state) => state.theme.value);

  return (
    <header className=" px-2 my-5 flex justify-evenly">
      <div>
        <h1
          className={`${
            theme === 'light' ? '' : 'text-white'
          } text-5xl lg:text-8xl text-center font-Text_Me_One mb-2 xl:my-3 `}
        >
          Press NFT
        </h1>
        <h2
          className={`${theme === 'light' ? '' : 'text-white'} text-2xl xl:text-4xl text-center `}
        >
          Where Art Meets Identity
        </h2>
        <h2
          className={`${
            theme === 'light' ? '' : 'text-white'
          } text-sm xl:text-base text-center mt-4 `}
        >
          $PRESS: 0x0000000000000000000000000000000000000000
        </h2>
      </div>
      <div>
        <video width="200" autoPlay loop muted>
          <source src={press_video} type="video/mp4" />
        </video>
      </div>
    </header>
  );
};

export default HeroSection;
