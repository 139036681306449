import React from 'react';

export default function RoadMap() {
  return (
    <div className="primary_bg3 border border-gray-200 rounded-lg shadow p-4 mb-10 text-center xl:w-[50vw] mx-auto">
      <div className="text-center text-2xl xl:text-4xl mb-5 ">
        <h3 className="text-center text-2xl xl:text-4xl mb-5 ">Road Map</h3>
        <hr className="border-[#c5c5c5] " />
      </div>
      <p className="mt-5 mb-3">
        We want to be completely transparent with you: currently, there is no fixed roadmap for
        Press.
      </p>
      <p className="mb-3">
        However, this doesn't mean that We won't continue to enhance and expand its features. Rather
        than listing vague "future utility" bullet points, We prefer to focus on actual progress.
      </p>
      <p className="mb-3">
        There are several goals We aim to achieve, We have began implementing designs for a scanner
        which would allow any user to scan a Press and see the wallet who owns it, also we would like
        to build a robust marketplace where you can freely buy, sell, and trade. Moreover, We have
        envisioned at least three exciting variations of Press that We would love to bring to life.
      </p>
      <p>
        Rest assured, if you hold a press, you can expect to benefit from any future endeavors We
        undertake.
      </p>
    </div>
  );
}
