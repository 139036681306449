import React from 'react';
import basePrint1 from '../assets/images/basePrint1.webp';
import basePrint2 from '../assets/images/basePrint2.webp';
import basePrint3 from '../assets/images/basePrint3.webp';
import basePrint4 from '../assets/images/basePrint4.webp';
import { ArrowRightIcon, ArrowDownIcon } from '@heroicons/react/24/outline';

export default function Step1() {
  return (
    <div className="bg-primary_bg1 dark:bg-[#4B5563]   rounded-lg shadow p-4 mb-10">
      <div>
        <h3 className="text-center text-2xl xl:text-4xl mb-5 dark:text-white ">
          Step 1: The Base Press
        </h3>
        <hr className="border-[#c5c5c5] " />
      </div>
      <section className="flex flex-col xl:flex-row my-5 xl:p-6 ">
        <section className="xl:w-2/3 text-center">
          <p className="dark:text-white">The first thing we need to do is get your base press.</p>
          <br />
          <p className="dark:text-white">There are 4 base press available.</p>
          <br />
          <div className="xl:flex justify-center">
            <div className=" xl:w-10/12 flex  justify-evenly rounded-lg border bg-white dark:bg-transparent shadow-md border-[#E5E7EB] py-2">
              <div className="dark:text-white">
                <img
                  src={basePrint1}
                  loading="lazy"
                  width={70}
                  height={70}
                  className="rounded-full"
                />
                Index
              </div>
              <div className="dark:text-white">
                <img
                  src={basePrint2}
                  loading="lazy"
                  width={70}
                  height={70}
                  className="rounded-full"
                />
                Middle
              </div>
              <div className="dark:text-white">
                <img
                  src={basePrint3}
                  loading="lazy"
                  width={70}
                  height={70}
                  className="rounded-full"
                />
                Ring
              </div>
              <div className="dark:text-white">
                <img
                  src={basePrint4}
                  loading="lazy"
                  width={70}
                  height={70}
                  className="rounded-full"
                />
                Little
              </div>
            </div>
          </div>
          <br />
          <p className="dark:text-white">Using our random number generator we select a press.</p>
        </section>
        <section className="flex items-center justify-center xl:p-6">
          <div className="xl:block hidden">
            <ArrowRightIcon className="w-11 h-12 text-black dark:text-white " />
          </div>
          <div className="flex justify-center xl:hidden my-3">
            <ArrowDownIcon className="w-11 h-12 text-black dark:text-white " />
          </div>
        </section>
        <section className="xl:w-2/3 flex  items-center justify-center">
          <div className=" xl:w-8/12 flex flex-col  items-center rounded-lg border bg-white dark:bg-transparent shadow-md border-[#E5E7EB] py-2">
            <img
              src={basePrint1}
              loading="lazy"
              width={170}
              height={170}
              className="rounded-full"
            />
            <p className="dark:text-white">Index press selected.</p>
          </div>
        </section>
      </section>
    </div>
  );
}
