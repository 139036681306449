import React from 'react';
import { useSelector } from 'react-redux';

const CardSection = () => {
  const theme = useSelector((state) => state.theme.value);
  return (
    <section
      className={` ${
        theme === 'light' ? '' : 'text-white'
      } flex flex-wrap justify-center  xl:grid  xl:grid-cols-3   mt-3`}
    >
      <div className="flex justify-center ">
        <article className="p-4 ">
          <div className="max-w-sm p-6 primary_bg1 border border-gray-300 rounded-lg shadow-md md:min-w-[375px]">
            <h1 className=" text-3xl font-Text_Me_One  tracking-wide font-semibold  text-center  text_primary ">
              Mint Details
            </h1>
            <hr className="my-4 border-[#c5c5c5]" />
            <div className="text-center">
              <p className=" font-semibold mb-1"> 5000 $Press per Nft</p>
              <p className=" font-semibold mb-1">2 Press Per Wallet</p>
              <p className=" font-semibold"> 10,000 Press NFT Supply </p>
              <br />
              <p className="">
                4 Base Press each with 20 lines and 20 possible colours for each line.
              </p>
              <br />
              <p className="">
                No predetermined art. Each press is styled using your wallet address, ensuring the
                creation process is completely fair.
              </p>
              <br />
              <p className="text-center">Want to learn how a press is made?</p>
            </div>
            <div className=" mt-5 flex justify-center ">
              <a className="btn_gradient" href="/how_its_made">
                Learn More
              </a>
            </div>
          </div>
        </article>
      </div>
      <div className="flex justify-center ">
        <article className="p-4 ">
          <div className="max-w-sm p-6 primary_bg2 border border-gray-300 rounded-lg shadow-md md:min-w-[375px]">
            <h1 className=" text-3xl font-Text_Me_One  tracking-wide font-semibold  text-center  text_primary ">
              About Press
            </h1>
            <hr className="my-4 black_hr" />
            <p className="text-center">
              Are you eager to learn more about Press?
              <br />
              <br /> Discover the mind behind its inception and find out about the future
              possibilities for Press and its printers. <br />
              <br /> Gain insights into how metadata is managed and explore the inner workings of
              the smart contract that powers the platform.
              <br />
              <br />
              Find all the information you need to satisfy your curiosity about Press right here.
            </p>
            <div className=" mt-5 flex justify-center ">
              <a className="btn_gradient" href="/about">
                Tell Me More
              </a>
            </div>
          </div>
        </article>
      </div>
      <div className="flex justify-center ">
        <article className="p-4 ">
          <div className="max-w-sm p-6 primary_bg3 border border-gray-300 rounded-lg shadow-md md:min-w-[375px]">
            <h1 className=" text-3xl font-Text_Me_One  tracking-wide font-semibold  text-center  text_primary ">
              Explore the collection
            </h1>
            <hr className="my-4 black_hr" />
            <p className="text-center">
              Embark on an exploration of the vast collection of Press available.
              <br />
              <br />
              Discover fascinating insights such as who holds the record for the most or fewest
              colors, and uncover the number of index Press.
              <br />
              <br />
              If you desire to envision your own press framed elegantly, just like the captivating
              examples showcased above, look no further.
              <br />
              <br />
              Click explore and enjoy .<br />
            </p>
            <div className=" mt-5 flex justify-center ">
              <button className="btn_gradient">Coming Soon</button>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default CardSection;
